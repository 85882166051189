import { render, staticRenderFns } from "./ribs.vue?vue&type=template&id=6d328bfe&scoped=true"
import script from "./ribs.vue?vue&type=script&lang=js"
export * from "./ribs.vue?vue&type=script&lang=js"
import style0 from "./ribs.vue?vue&type=style&index=0&id=6d328bfe&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d328bfe",
  null
  
)

export default component.exports